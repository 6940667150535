const resource = 'client'

export default (axios) => ({
  requests (params) {
    return axios.get(`${resource}/requests`, params)
  },

  mutations (params) {
    return axios.get(`${resource}/mutations`, params)
  },

  count (params) {
    return axios.get(`${resource}/count`, params)
  },
})
