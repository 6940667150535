import statisticsRepository from './statisticsRepository'
import googleRepository from './googleRepository'
import clientRepository from './clientRepository'
import recommendationRepository from './recommendationRepository'

export default (axios) => ({
  statistics: statisticsRepository(axios),
  google: googleRepository(axios),
  client: clientRepository(axios),
  recommendation: recommendationRepository(axios),
})
