import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

const urls = {
  development: 'http://localhost:8000/api/dashboard/',
  production: 'http://landbot.dev.aiims.com.au/api/dashboard/',
}

const instance = axios.create({
  baseURL: urls[process.env.NODE_ENV],
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

Vue.use(VueAxios, instance)
