import Vue from 'vue'
import createRepository from '@/repositories'

const plugin = {
  install (Vue, _options) {
    Vue.prototype.$repo = createRepository(Vue.axios)
  },
}

Vue.use(plugin)
