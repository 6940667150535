const resource = 'google'

export default (axios) => ({
  budgetIncreaseCount (params) {
    return axios.get(`${resource}/budget-increase`, params)
  },
  pauseCount (params) {
    return axios.get(`${resource}/pause`, params)
  },
})
